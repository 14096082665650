h3, .h3 {
    margin: 0 !important;
}

h5, .h5 {
    margin-bottom: 5px !important;
}

.award-description p {
    padding: 20px;
}

.nomination-form {
    width: 1000px;
    max-width: 1000px;
    min-width: 570px;
    align-items: left;
    background-color: white;
    margin-bottom: 20px;
}

.nomination-header {
    color: white;
    padding: 15px 0 15px 0;
    border-radius: 5px 5px 0 0;
}

.nomination-body {
    box-shadow: 1px 2px 10px #888888;
    border-radius: 5px;
    padding: 10px 20px 30px 20px;
    margin-bottom: 20px;
    color: white;
}

.nomination-questions {
    padding: 10px
}

.nomination-questions:nth-child(0) {
    margin-top: 10px !important;
}

.nomination-submit {
    height: 54px;
}

.nomination-submit button {
    border-radius: 5px;
    width: 100%;
    height: 70px;
    font-weight: bold;
    background-color: grey;
    color: white;
    font-size: 25px;
    box-shadow: 1px 2px 10px #888888;
}

.nomination-submit button:enabled {
    background-color: olivedrab;
}

.nomination-submit button:hover:enabled {
    background-color: white;
    color: olivedrab;
}

.reason {
    margin-top: 5px;
    text-align: left;
}

.reason-textarea {
    border-radius: 5px;
    width: 100%;
    height: 100px;
    font-size: 18px;
    resize: none;
    overflow: hidden;
}

.dot {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    border: 1px solid grey;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: grey;
}

.line {
    letter-spacing: -3px;
}

.question {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 40px
}

#reason .question-remaining {
    font-size: 1rem;
}

#reason span {
    font-size: 12px;
}

.h6-question {
    margin-bottom: 5px !important;
    color: #616266;
}

.question-remaining {
    font-size: 12px;
}

.wrapper {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 1%;
    text-align: left;
    overflow: hidden; /* will contain if #first is longer than #second */
}
.first {
    text-align: center;
    padding-top: 1%;
}
.second {
    overflow: hidden; /* if you don't want #second to wrap below #first */
}

.input {
    height: 40px;
    width: 300px;
}

.subtitle {
    margin: 0 0 1em 0;
  }
.fancy {
    line-height: 0.5;
    text-align: center;
}
.fancy span {
    display: inline-block;
    position: relative;  
}
.fancy span:before,
.fancy span:after {
    content: "";
    padding-top: 3%;
    position: absolute;
    height: 5px;
    border-bottom: 1px solid black;
    top: 0;
    width: 35%;
}
.fancy span:before {
    right: 100%;
    margin-right: 15px;
}
.fancy span:after {
    left: 100%;
    margin-left: 15px;
}



/* @media screen and (min-width: 1366px){
    .dot {
        margin: 0.4em;
    }

    .wrapper {
        max-width: 100%;
    }

    .first {
        width: 30%;
    }

    .wrapper-department {
        max-width: 100%;
        margin: 0 auto;
        align-content: left;
    }
    .first-department {
        width: 30%;
        float:left; 
    }
    .second-department {
        width: 70%;
        float: left;
        overflow: hidden; 
    }

    .selector-department {
        width: 100%;
        height: 40px;
        border-radius: 0;
    }
    
}

@media screen and (min-width: 1600px){
    .dot { 
        margin: 0.7em;
    }
} 


@media screen and (min-width: 1850px) {
    .dot {
        margin: 1.1em;
    }
} */
