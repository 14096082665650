.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn {
    min-width: 76.05px;
}

.Toastify__toast--error { 
    background-color: #e53e43 !important
}

.Toastify__toast--success {
    background-color: #78A02E !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
