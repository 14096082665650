h5,
.h5 {
  margin-bottom: 0 !important;
}

.award-outer {
  padding: 15px 15px;
  height: 300px;
  min-width: 200px;
  display: flex;
  overflow: hidden;
}

.award {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

.award-name {
  background-color: #e53e43;
  color: white;
  height: 20%;
  width: 100%;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.award-body {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.award a {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.award-image {
  height: 100px;
}

.award-nominate {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.award-nominate a {
  width: 100%;
  height: 100%;
  line-height: 50px;
  color: #e53e43;
  background-color: white;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.award-nominate a:hover {
  transition: background-color 0.3s ease;
  color: white;
  background-color: #e53e43;
  text-decoration: none;
}

.corner-ribbon {
  width: 200px;
  background: #f0f0f0;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: grey;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon::before,
.corner-ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 8px solid darkgrey;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.corner-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-left::before,
.corner-ribbon.top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.corner-ribbon.top-left::before {
  top: 42px;
  right: 6.5px;
  transform: rotate(45deg);
}

.corner-ribbon.top-left::after {
  bottom: -8px;
  left: 6.5px;
  transform: rotate(45deg);
}

.pulse {
  animation: pulse 2s ease-out;
  animation-iteration-count: infinite; 
  opacity: 0.5;
  font-size: 20px;
  font-weight: 600
}

@keyframes pulse {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}