.logo {
    width: 50px;
    padding: 10px;
}

.navbar { 
    background-color: #e53e43 !important;
}

.nav-link {
    color: white !important;
    font-weight: 500;
    border: 1px solid #00000000;
    margin-left: 5px;
    transform: background-color 0.3s ease !important;
}

.nav-link.active {
    background-color: white !important;
    color: #e53e43 !important;
    border-radius: 5px;
}

.nav-link:hover {
    background-color: white;
    color: #e53e43 !important;
    border-radius: 5px;
    
}

.nav-brand {
    margin: 5px !important;
}