html, body {
    height: 100%;
    background-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
    height: 100%
}
.App {
  text-align: center;
  background-color: white;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.btn {
    min-width: 76.05px;
}

.Toastify__toast--error { 
    background-color: #e53e43 !important
}

.Toastify__toast--success {
    background-color: #78A02E !important;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo {
    width: 50px;
    padding: 10px;
}

.navbar { 
    background-color: #e53e43 !important;
}

.nav-link {
    color: white !important;
    font-weight: 500;
    border: 1px solid #00000000;
    margin-left: 5px;
    -webkit-transform: background-color 0.3s ease !important;
            transform: background-color 0.3s ease !important;
}

.nav-link.active {
    background-color: white !important;
    color: #e53e43 !important;
    border-radius: 5px;
}

.nav-link:hover {
    background-color: white;
    color: #e53e43 !important;
    border-radius: 5px;
    
}

.nav-brand {
    margin: 5px !important;
}
.rating-bar {
    width: 100%;
    display: inline-flex;
    margin-top: 5px;
}
select:required:invalid{
 color: grey !important;
}
h3, .h3 {
    margin: 0 !important;
}

h5, .h5 {
    margin-bottom: 5px !important;
}

.award-description p {
    padding: 20px;
}

.nomination-form {
    width: 1000px;
    max-width: 1000px;
    min-width: 570px;
    align-items: left;
    background-color: white;
    margin-bottom: 20px;
}

.nomination-header {
    color: white;
    padding: 15px 0 15px 0;
    border-radius: 5px 5px 0 0;
}

.nomination-body {
    box-shadow: 1px 2px 10px #888888;
    border-radius: 5px;
    padding: 10px 20px 30px 20px;
    margin-bottom: 20px;
    color: white;
}

.nomination-questions {
    padding: 10px
}

.nomination-questions:nth-child(0) {
    margin-top: 10px !important;
}

.nomination-submit {
    height: 54px;
}

.nomination-submit button {
    border-radius: 5px;
    width: 100%;
    height: 70px;
    font-weight: bold;
    background-color: grey;
    color: white;
    font-size: 25px;
    box-shadow: 1px 2px 10px #888888;
}

.nomination-submit button:enabled {
    background-color: olivedrab;
}

.nomination-submit button:hover:enabled {
    background-color: white;
    color: olivedrab;
}

.reason {
    margin-top: 5px;
    text-align: left;
}

.reason-textarea {
    border-radius: 5px;
    width: 100%;
    height: 100px;
    font-size: 18px;
    resize: none;
    overflow: hidden;
}

.dot {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    border: 1px solid grey;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: grey;
}

.line {
    letter-spacing: -3px;
}

.question {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 40px
}

#reason .question-remaining {
    font-size: 1rem;
}

#reason span {
    font-size: 12px;
}

.h6-question {
    margin-bottom: 5px !important;
    color: #616266;
}

.question-remaining {
    font-size: 12px;
}

.wrapper {
    max-width: 500px;
    margin: 0 auto;
    padding-top: 1%;
    text-align: left;
    overflow: hidden; /* will contain if #first is longer than #second */
}
.first {
    text-align: center;
    padding-top: 1%;
}
.second {
    overflow: hidden; /* if you don't want #second to wrap below #first */
}

.input {
    height: 40px;
    width: 300px;
}

.subtitle {
    margin: 0 0 1em 0;
  }
.fancy {
    line-height: 0.5;
    text-align: center;
}
.fancy span {
    display: inline-block;
    position: relative;  
}
.fancy span:before,
.fancy span:after {
    content: "";
    padding-top: 3%;
    position: absolute;
    height: 5px;
    border-bottom: 1px solid black;
    top: 0;
    width: 35%;
}
.fancy span:before {
    right: 100%;
    margin-right: 15px;
}
.fancy span:after {
    left: 100%;
    margin-left: 15px;
}



/* @media screen and (min-width: 1366px){
    .dot {
        margin: 0.4em;
    }

    .wrapper {
        max-width: 100%;
    }

    .first {
        width: 30%;
    }

    .wrapper-department {
        max-width: 100%;
        margin: 0 auto;
        align-content: left;
    }
    .first-department {
        width: 30%;
        float:left; 
    }
    .second-department {
        width: 70%;
        float: left;
        overflow: hidden; 
    }

    .selector-department {
        width: 100%;
        height: 40px;
        border-radius: 0;
    }
    
}

@media screen and (min-width: 1600px){
    .dot { 
        margin: 0.7em;
    }
} 


@media screen and (min-width: 1850px) {
    .dot {
        margin: 1.1em;
    }
} */

h5,
.h5 {
  margin-bottom: 0 !important;
}

.award-outer {
  padding: 15px 15px;
  height: 300px;
  min-width: 200px;
  display: flex;
  overflow: hidden;
}

.award {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

.award-name {
  background-color: #e53e43;
  color: white;
  height: 20%;
  width: 100%;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.award-body {
  flex: 1 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.award a {
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.award-image {
  height: 100px;
}

.award-nominate {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.award-nominate a {
  width: 100%;
  height: 100%;
  line-height: 50px;
  color: #e53e43;
  background-color: white;
  font-weight: bold;
  border-radius: 0 0 5px 5px;
  z-index: 1;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.award-nominate a:hover {
  transition: background-color 0.3s ease;
  color: white;
  background-color: #e53e43;
  text-decoration: none;
}

.corner-ribbon {
  width: 200px;
  background: #f0f0f0;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: grey;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon::before,
.corner-ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 8px solid darkgrey;
}

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.corner-ribbon.top-left {
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-left::before,
.corner-ribbon.top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}

.corner-ribbon.top-left::before {
  top: 42px;
  right: 6.5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.corner-ribbon.top-left::after {
  bottom: -8px;
  left: 6.5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.pulse {
  -webkit-animation: pulse 2s ease-out;
          animation: pulse 2s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; 
  opacity: 0.5;
  font-size: 20px;
  font-weight: 600
}

@-webkit-keyframes pulse {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}

@keyframes pulse {
  0% { 
      opacity: 0.5;
  }
  50% { 
      opacity: 1.0;
  }
  100% { 
      opacity: 0.5;
  }
}
.container {
    max-width: 1440px !important;
    padding: 20px 20px 20px 20px;
    margin: 0 auto;
    justify-content: center;
    display: inline-flex;
    flex-wrap: wrap;
}
